import http from "./httpService";
import config from "../../config.json";

const apiFootball = config.apiUrl + "/round";
const apiNews = config.apiUrl + "/news";
const apiLogo = config.apiUrl + "/logo";
const apiGoal = config.apiUrl + "/goal";
const apiRed = config.apiUrl + "/redcard";
const apiYellow = config.apiUrl + "/yellowcard";

export async function register(data) {
  try {
    return await http.post(apiFootball, data);
  } catch (error) {
    alert(error);
  }
}
export async function registerGoal(data) {
  try {
    return await http.post(apiGoal, data);
  } catch (error) {
    alert(error);
  }
}
export async function registerRed(data) {
  try {
    return await http.post(apiRed, data);
  } catch (error) {
    alert(error);
  }
}
export async function registerYellow(data) {
  try {
    return await http.post(apiYellow, data);
  } catch (error) {
    alert(error);
  }
}

export async function registerNews(data) {
  try {
    return await http.post(apiNews, data);
  } catch (error) {
    alert(error);
  }
}
export async function update(data) {
  try {
    const fdata = { ...data };
    delete fdata.id;
    return await http.put(apiFootball + "/" + data.id, fdata);
  } catch (error) {
    alert(error);
  }
}
export async function getDatas() {
  try {
    return await http.get(apiFootball);
  } catch (error) {
    alert(error);
  }
}
export async function getNews() {
  try {
    return await http.get(apiNews);
  } catch (error) {
    alert(error);
  }
}

export async function getData(id) {
  try {
    return await http.get(apiFootball + "/" + id);
  } catch (error) {
    alert(error);
  }
}
export async function getLogo() {
  try {
    return await http.get(apiLogo);
  } catch (error) {
    alert(error);
  }
}
export async function DeleteLogo(id) {
  try {
    return await http.delete(apiLogo + "/" + id);
  } catch (error) {
    alert(error);
  }
}
